var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container offset"},[_c('div',{staticClass:"flex wrapable",style:({ marginBottom: 15 })},[_c('div',{staticClass:"flex-main"},[_c('h1',{style:({ lineHeight: 1.4, margin: 0, padding: 2 })},[_vm._v(" PERFIL DE "+_vm._s(_vm.user.name.toUpperCase())+" ")]),_c('div',{staticClass:"panel pt-2"},[_c('div',{staticClass:"panel-body pad"},[_c('CForm',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('h3',{style:({ 'font-size': '30px', margin: '0px 0px 30px' })},[_vm._v(" Editar perfil ")]),_c('CRow',{staticClass:"mb-4",attrs:{"form":""}},[_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"id":"name","name":"name","placeholder":"Username","autocomplete":"username","disabled":""},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{style:({ color: '#fab700' }),attrs:{"name":"cil-user"}})]},proxy:true}]),model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1)],1),_c('CRow',{staticClass:"mb-4",attrs:{"form":""}},[_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"placeholder":"Password","type":"password","id":"password","name":"password","autocomplete":"new-password","addInputClasses":{
                    error: _vm.$v.password.$error,
                  }},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{style:({ color: '#fab700' }),attrs:{"name":"cil-lock-locked"}})]},proxy:true}]),model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.password.$model"}}),(_vm.$v.password.$dirty && !_vm.$v.password.required)?_c('span',{staticClass:"error"},[_vm._v("Rellena la contraseña.")]):_vm._e()],1),_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"placeholder":"Repeat password","type":"password","id":"repeatPassword","addInputClasses":{
                    error: _vm.$v.repeatPassword.$error,
                  },"autocomplete":"new-password"},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{style:({ color: '#fab700' }),attrs:{"name":"cil-lock-locked"}})]},proxy:true}]),model:{value:(_vm.$v.repeatPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.repeatPassword, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.repeatPassword.$model"}}),(
                    _vm.$v.repeatPassword.$dirty &&
                    !_vm.$v.repeatPassword.sameAsPassword
                  )?_c('span',{staticClass:"error"},[_vm._v("Las contraseñas deben coincidir.")]):_vm._e()],1)],1),_c('CButton',{attrs:{"color":"warning","variant":"ghost","block":""}},[_vm._v("Editar constraseña")])],1)],1)]),_c('h1',{style:({ lineHeight: 1.4, margin: 0, padding: 2 })},[_vm._v(" Mis personajes ")]),_c('CharacterList')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }