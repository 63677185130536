var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel"},[(_vm.loading)?_c('div',{staticClass:"panel-body text-center m-5"},[_c('vue-loaders',{attrs:{"name":"pacman","color":"#fab700"}})],1):_c('div',{staticClass:"panel-body"},[_c('CDataTable',{attrs:{"items":_vm.playerList,"fields":_vm.campos,"sorter":"","hover":"","clickable-rows":"","addTableClasses":'data-table mt-4',"noItemsView":{
          noResults: ' sin resultados disponibles ',
          noItems: ' sin resultados',
        }},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"guild_name",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[(item.guild_name && item.guild_name != '')?_c('span',[_c('CButton',{attrs:{"color":"link"}},[_vm._v(_vm._s(item.guild_name))])],1):_c('span',[_vm._v(" Sin hermandad ")])])]}},{key:"name",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('img',{staticClass:"icon rounded-circle",attrs:{"src":item.media}}),(item.name)?_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()])]}},{key:"realm",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[(item.realm)?_c('span',[_vm._v(" "+_vm._s(item.realm)+" ")]):_vm._e()])]}},{key:"actions",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_c('CButton',{attrs:{"color":"danger","variant":"ghost"},on:{"click":function($event){return _vm.deleteCharacter(item)}}},[_vm._v(" Eliminar personaje ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }